<header>
  <mat-toolbar class="noselect main-toolbar">
    <div>
      <h1 class="no-wrap">
        <a href="https://dotnet.stockindicators.dev" target="_blank" rel="noopener">
          stock indicators <small>for .NET</small>
        </a>
      </h1>
      <h2 class="no-wrap">
        <a href="https://github.com/facioquo/stock-charts" target="_blank" rel="noopener noreferrer">
          <strong>demo site</strong>
        </a>
      </h2>
    </div>
  </mat-toolbar>
</header>

<main id="main-content">
  <router-outlet></router-outlet>
</main>
